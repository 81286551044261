export const federationGateway = {
    baseUrl: 'https://fg.ciam.preprod.aws.scania.com',
    redirectUri:`${window.location.protocol}//${window.location.host}/login-callback`,
    authURL: 'auth/realms/scania/protocol/openid-connect',
};

export const environment = {
    logLevel: 'debug',
    production: false,
    apiUrl: 'https://apim-br.devtest.aws.scania.com/rena-dev/1.0/api/',
    auth_cookie_domain: 'scania.com',
    auth_cookie: 'authorization',
    refresh_cookie: 'authorizationRefresh',
    tokenUri_key: 'tokenUri',
    clientId_key: 'clientId',
    loginType_key: 'loginType',
    refreshTokenUri_key: 'refreshtokenUri',
    federation_token: 'openIdAuthToken',
    internalAuthorizerUri: 'https://salesforce-authenticator.slasf.devtest.aws.scania.com/api/config',
    externalAuthorizerUri: 'https://salesforce-authenticator.slasf.devtest.aws.scania.com/api/config', 
    authUri: `${federationGateway.baseUrl}/${federationGateway.authURL}/auth?client_id={client_id}&response_type=code&scope=openid&redirect_uri=${federationGateway.redirectUri}&nonce=foo&kc_idp_hint={login_type}`,
    redirectUri: federationGateway.redirectUri,
    envFlag: 'DEV'
};